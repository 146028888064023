import axios from "axios";
import jsSHA from 'jssha';

const { MD5 } = require("crypto-js");
const CryptoJS = require("crypto-js");

function encode(myString) {
  const encodedWord = CryptoJS.enc.Utf8.parse(myString); // encodedWord Array object
  const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
  return encoded;
}

export const CHECKOUT_STARTING = 'CHECKOUT_STARTING';
export const CHECKOUT_SUCCESSFULL = 'CHECKOUT_SUCCESSFULL';
export const CHECKOUT_FAILED = 'CHECKOUT_FAILED';

export function checkoutStarting(payload) {
    return {
        type: CHECKOUT_STARTING,
        payload: payload
    };
}

export function checkoutSuccessfull(payload) {
    return {
        type: CHECKOUT_SUCCESSFULL,
        payload: payload
    };
}

export function checkoutFailed(payload) {
    return {
        type: CHECKOUT_FAILED,
        payload: payload
    };
}

class Librapay {
  form 		= document.getElementById("PaymentForm");
  merch_name = "MAGICBRODERY SRL";
  merch_url 	= "desenbrodat.ro";
  merch_email 		= "ioana.laura.gilca@gmail.com";
  key 		= "3692fe7844123fb72144d0e07b21cc67";
  merchant 	= "000000088003606";
  terminal 	= "88003606";
  backref 	= "https://desenbrodat.ro/order/";

  email 		= "";
  amount 	= "0.00";
  currency 	= "RON";
  order		= "";
  desc 		= "";
  trtype 	= 0;
  merch_gmt 	= "-";
  psign 		= "";
  string 	= "";
  timestamp 	= "";
  nonce 		= "";

  getNonce = () => {
    this.nonce = String(MD5(String("desenbrodatkey_") + String(Math.floor(Math.random() * 9999999 + 99999)))).toLocaleUpperCase();

    return this.nonce;
  }

  getTimestamp = () => {
    this.timestamp += String(new Date().getFullYear());
    this.timestamp += String((new Date().getMonth()+1) < 10 ? "0" + String((new Date().getMonth()+1)) : String((new Date().getMonth()+1)));
    this.timestamp += String(new Date().getDate() < 10 ? "0" + String(new Date().getDate()) : String(new Date().getDate()));
    this.timestamp += String(new Date().getHours() < 10 ? "0" + String(new Date().getHours()) : String(new Date().getHours()));
    this.timestamp += String(new Date().getMinutes() < 10 ? "0" + String(new Date().getMinutes()) : String(new Date().getMinutes()));
    this.timestamp += String(new Date().getSeconds() < 10 ? "0" + String(new Date().getSeconds()) : String(new Date().getSeconds()));

    return this.timestamp;
  }

  getString = () => {
    this.string = `${String(Number(this.amount).toFixed(2)) !== "-" ? String(Number(this.amount).toFixed(2)).length : "-"}${String(Number(this.amount).toFixed(2))}`
    + `${String(this.currency) !== "-" ? String(this.currency).length : "-"}${String(this.currency)}`
    + `${String(this.order) !== "-" ? String(this.order).length : "-"}${String(this.order)}`
    + `${String(this.desc) !== "-" ? String(this.desc).length : "-"}${String(this.desc)}`
    + `${String(this.merch_name) !== "-" ? String(this.merch_name).length : "-"}${String(this.merch_name)}`
    + `${String(this.merch_url) !== "-" ? String(this.merch_url).length : "-"}${String(this.merch_url)}`
    + `${String(this.merchant) !== "-" ? String(this.merchant).length : "-"}${String(this.merchant)}`
    + `${String(this.terminal) !== "-" ? String(this.terminal).length : "-"}${String(this.terminal)}`
    + `${String(this.merch_email) !== "-" ? String(this.merch_email).length : "-"}${String(this.merch_email)}`
    + `${String(this.trtype) !== "-" ? String(this.trtype).length : "-"}${String(this.trtype)}`
    + `--`
    + `${String(this.timestamp) !== "-" ? String(this.timestamp).length : "-"}${String(this.timestamp)}`
    + `${String(this.nonce) !== "-" ? String(this.nonce).length : "-"}${String(this.nonce)}`
    + `${String(this.backref) !== "-" ? String(this.backref).length : "-"}${String(this.backref)}`;

    return this.string;
  }

  getSign = () => {
    const hashObj = new jsSHA("SHA-1", "TEXT");

    hashObj.setHMACKey(this.key, "HEX");
    hashObj.update(this.string);

    this.psign = hashObj.getHMAC("HEX").toLocaleUpperCase();

    return this.psign;
  }

  setValues = (props) => {
    this.amount = props.amount;
    this.order = props.order;
    this.desc = "Comanda #" + props.order;
    this.products = props.products;
    this.email = props.email;
    this.backref += props.orderId;
  }

  geenerateForm = (props) => {
    const form = document.createElement("form");
    form.setAttribute('method',"post");
    form.setAttribute('action',"https://secure.librapay.ro/pay_auth.php");
    form.hidden = true;

    const paymentAmount = document.createElement("input");
    paymentAmount.setAttribute('type', "text");
    paymentAmount.setAttribute('name', "AMOUNT");
    paymentAmount.value = String(Number(this.amount).toFixed(2));
    form.appendChild(paymentAmount);

    const paymentCurrency = document.createElement("input");
    paymentCurrency.setAttribute('type', "text");
    paymentCurrency.setAttribute('name', "CURRENCY");
    paymentCurrency.value = this.currency;
    form.appendChild(paymentCurrency);

    const paymentOrder = document.createElement("input");
    paymentOrder.setAttribute('type', "text");
    paymentOrder.setAttribute('name', "ORDER");
    paymentOrder.value = this.order;
    form.appendChild(paymentOrder);

    const paymentMerchName = document.createElement("input");
    paymentMerchName.setAttribute('type', "text");
    paymentMerchName.setAttribute('name', "MERCH_NAME");
    paymentMerchName.value = this.merch_name;
    form.appendChild(paymentMerchName);

    const paymentMerchURL = document.createElement("input");
    paymentMerchURL.setAttribute('type', "text");
    paymentMerchURL.setAttribute('name', "MERCH_URL");
    paymentMerchURL.value = this.merch_url;
    form.appendChild(paymentMerchURL);

    const paymentMerchant = document.createElement("input");
    paymentMerchant.setAttribute('type', "text");
    paymentMerchant.setAttribute('name', "MERCHANT");
    paymentMerchant.value = this.merchant;
    form.appendChild(paymentMerchant);

    const paymentEmail = document.createElement("input");
    paymentEmail.setAttribute('type', "text");
    paymentEmail.setAttribute('name', "EMAIL");
    paymentEmail.value = this.email;
    form.appendChild(paymentEmail);

    const paymentTrType = document.createElement("input");
    paymentTrType.setAttribute('type', "text");
    paymentTrType.setAttribute('name', "TRTYPE");
    paymentTrType.value = this.trtype;
    form.appendChild(paymentTrType);

    const paymentDesc = document.createElement("input");
    paymentDesc.setAttribute('type', "text");
    paymentDesc.setAttribute('name', "DESC");
    paymentDesc.value = this.desc;
    form.appendChild(paymentDesc);

    const paymentTerminal = document.createElement("input");
    paymentTerminal.setAttribute('type', "text");
    paymentTerminal.setAttribute('name', "TERMINAL");
    paymentTerminal.value = this.terminal;
    form.appendChild(paymentTerminal);

    const paymentTimestamp = document.createElement("input");
    paymentTimestamp.setAttribute('type', "text");
    paymentTimestamp.setAttribute('name', "TIMESTAMP");
    paymentTimestamp.value = this.timestamp;
    form.appendChild(paymentTimestamp);

    const paymentNonce = document.createElement("input");
    paymentNonce.setAttribute('type', "text");
    paymentNonce.setAttribute('name', "NONCE");
    paymentNonce.value = this.nonce;
    form.appendChild(paymentNonce);

    const paymentBackref = document.createElement("input");
    paymentBackref.setAttribute('type', "text");
    paymentBackref.setAttribute('name', "BACKREF");
    paymentBackref.value = this.backref;
    form.appendChild(paymentBackref);

    const paymentPSIGN = document.createElement("input");
    paymentPSIGN.setAttribute('type', "text");
    paymentPSIGN.setAttribute('name', "P_SIGN");
    paymentPSIGN.value = this.psign;
    form.appendChild(paymentPSIGN);

    const paymentSTRING = document.createElement("input");
    paymentSTRING.setAttribute('type', "text");
    paymentSTRING.setAttribute('name', "STRING");
    paymentSTRING.value = this.string;
    form.appendChild(paymentSTRING);

    const PaymentDataCustom = document.createElement("input");
    PaymentDataCustom.setAttribute('type', "text");
    PaymentDataCustom.setAttribute('name', "DATA_CUSTOM");
    PaymentDataCustom.value = encode(JSON.stringify({
      ProductsData: this.products.filter(value => JSON.stringify(value) !== "{}"),
      UserData: {
        Email: props.email,
        Name: props.firstname + props.lastname,
        Phone: props.phone,
        BillingEmail: props.email,
        BillingName: props.firstname + props.lastname,
        BillingPhone: props.phone,
        BillingCity: props.city,
        BillingCountry: "ROMANIA",
        ShippingEmail: props.email,
        ShippingName: props.firstname + props.lastname,
        ShippingAddress: props.address,
        ShippingPhone: props.phone,
        ShippingCity: props.city,
        ShippingCountry: "ROMANIA"
      }
    }));

    form.appendChild(PaymentDataCustom);
    document.body.appendChild(form);

    return form;
  }
}

const MakePayment = (props) => {
    const orderId = String(props.insertId);
    const order = "1" + (5 - orderId.length > 0 ? "0".repeat(5 - orderId.length) + orderId : orderId);
    let productsData = [];
    let totalPrice = 0;

    for (let product of props.cart) {
      productsData.push({
        ItemName: product.title,
        Quantity: product.amount,
        Price: product.price,
        ProductId: product.id
      });
    }

    for (let product of productsData) {
      totalPrice += product.Price * product.Quantity;
    }

    const LibrapayPayment = new Librapay();

    LibrapayPayment.setValues({
      amount: totalPrice,
      order: order,
      orderId: props.insertId,
      products: productsData,
      ...props
    });

    LibrapayPayment.getNonce();
    LibrapayPayment.getTimestamp();
    LibrapayPayment.getString();
    LibrapayPayment.getSign();

    const form = LibrapayPayment.geenerateForm({
      amount: totalPrice,
      order: order,
      ...props,
      products: productsData
    });

    console.log(form);

    form.submit();
}

export const checkout = (props) => {
    return async (dispatch) => {
        dispatch(checkoutStarting());

        axios.post(`${process.env.REACT_APP_API_KEY}/orders`,
        {
            ...props
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })
        .then(res => {
            const request = res.data;
            console.log(props);
            if (props.paymentMethod == "librapay") {
              console.log("payment needs to happen")
              MakePayment({
                ...props,
                ...request
                });
            }
            else {
              const urlParams = new URLSearchParams(window.location.search);

              urlParams.set('id', request.insertId);

              window.history.pushState(null, null, "/order?" + urlParams.toString());
              window.location.reload();
            }

            dispatch(checkoutSuccessfull(res.data));
        }).catch(err => {
            dispatch(checkoutFailed(err?.response?.data?.error?.message || err?.message || err));
        });
    }
};

export const setPaymentSuccessful = ({id}) => {
  axios.post(`${process.env.REACT_APP_API_KEY}/payment`, {
    id: id,
    RC: "00"
  },
  {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem("token")}`
      }
  })
  .then(res => {
  }).catch(err => {
  });
};