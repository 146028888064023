import React from 'react'

const Whatsapp =() => {
  return (
    <a href="https://wa.me/40749602012" className="wapp--button" target="_blank">
        <i className="fa fa-whatsapp"></i>
    </a>
  )
}

export default Whatsapp